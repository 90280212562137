<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Whitelist
        </h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert align-v="center" variant="warning" :show="saveMessage !== ''">{{ this.saveMessage }}</b-alert>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button :disabled="saveDisabled" variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-form-group label="Domains">
        <b-form-tags v-model="savedDomains"></b-form-tags>
    </b-form-group>
  </b-overlay>
</template>
<script>
  export default { 
    data() {
      return {
          isLoading: false,
          saveDisabled: true,
          saveMessage: "",
          savedDomains: []
      }
    },
    watch: {
    domains: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
    async created() {
        this.isLoading = false;
        this.saveDisabled = true;
        this.saveMessage = "";
        await this.fetchAll();
        this.savedDomains = this.domains
    },
    computed: {
        domains() {
            return this.$store.state.Whitelist.domains
        }
    },
    methods: {
        async fetchAll() {
            this.isLoading = true;
            await this.$store.dispatch("Whitelist/fetchAll");
            this.isLoading = false;
        },
        async doSave() {
            this.isLoading = true;
            await this.$store.dispatch("Whitelist/update", this.savedDomains);
            this.$root.$bvToast.toast("Whitelist saved.", { variant: "success" });
            this.saveDisabled = true;
            this.saveMessage = "";
            this.isLoading = false;
        }
    }
  }
  
</script>